
import { defineComponent, ref, watch, onMounted, Ref, computed } from "vue";

import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import moment from "moment";
import useEmitter from "@/composables/Emmiter";
import { useStore } from "vuex";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import Api from "@/services/Api";

export interface RelatoryFilter {
  grupo: any[];
  loja: any[];
  eventos: any[];
  usuarios: any[];
  data: any[];
  url: string | null;
  metodo: string | null;
  placa: string | null;
  headers: string | null;
  envio: string | null;
  retorno: string | null;
}

export default defineComponent({
  name: "FilterRelatory",
  components: {
    FilterBase,
    ElConfigProvider,
  },

  setup(props, { emit }) {
    const emitter = useEmitter();

    const activeModal = ref(false);
    const closeModal = ref(false);
    const storeGroups: Ref<any[]> = ref([]);
    const lojas: Ref<any[]> = ref([]);
    const users: Ref<any[]> = ref([]);
    const typeRelatorio: Ref<number | null> = ref(null)
    const relatoryFilter: Ref<RelatoryFilter> = ref({ 
        grupo: [],
        loja: [], 
        eventos: [],
        usuarios: [], 
        data: [], 
        url: null,
        metodo: null,
        placa: null,
        headers: null,
        envio: null,
        retorno: null,
      });

    async function getStoreGroups() {
      const { data } = await Api.get("getAllStoreGroup");
      storeGroups.value = data.grupos;
    }

    async function getLojas(relatoryFilter) {
      const res = await Api.get("getAllStores", { idGrupo: relatoryFilter.value.grupo });
      lojas.value = res.data.lojas;
    }

    async function getAllUsers() {
      const { data } = await Api.get("getAllUsers");
      users.value = data;
    }

    function formatarDataParaRelatorio(data: any) {
      const dataFormatada = moment(data).format('DD/MM/YYYY');
      return dataFormatada;
    }

    async function enviarEmit() {
      relatoryFilter.value.data[0] = formatarDataParaRelatorio(relatoryFilter.value.data[0])
      relatoryFilter.value.data[1] = formatarDataParaRelatorio(relatoryFilter.value.data[1])
      await emitter.emit("filtro-relatorios", {
        filtroRelatorios: relatoryFilter.value,
      });
      closeModal.value = !closeModal.value;
    }

    watch(
      () => relatoryFilter.value.grupo,
      () => {
        getLojas(relatoryFilter);
      }
    );

    const modeloRelatorio = computed(() => {
        return window.location.pathname;
    })

    onMounted(() => {
      if(modeloRelatorio.value.split('/')[2] === 'buscaPlaca') return typeRelatorio.value = 1
      if(modeloRelatorio.value.split('/')[2] === 'conecta') return typeRelatorio.value = 2
      if(modeloRelatorio.value.split('/')[2] === 'utilizacao') return typeRelatorio.value = 3
      if(modeloRelatorio.value.split('/')[2] === 'eventos') return typeRelatorio.value = 4
    })

    onMounted(() => {
      getStoreGroups();
      getLojas(relatoryFilter);
      getAllUsers();
    });

    return { relatoryFilter, storeGroups, lojas, enviarEmit, closeModal, ElConfigProvider, ptBr, modeloRelatorio, typeRelatorio, users};
  },
});
